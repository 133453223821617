import { useNavigate, useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from 'urql';
import { FormikValues } from 'formik';

import { Drawer, Form, useMakeTestId } from '@serenityapp/components-react-web';
import { RouteParamId } from '@serenityapp/domain';
import { snackAdd } from '@serenityapp/redux-store';
import { ClientApi } from '@serenityapp/api-client-graph';
import { useDeviceDetails } from '@serenityapp/client-data';

import { validationSchema } from './validationSchema';
import DeviceFields from './DeviceFields';
import { isAlexaDevice } from './utils';
import DeviceEditDrawerSkeleton from './DeviceEditDrawerSkeleton';
import FormikDrawer from '../components/FormikDrawer';
import validateWithZod from '../../../utils/validate-formik-with-zod';

const DeviceEditDrawer = () => {
  const { id } = useParams<RouteParamId>() as RouteParamId;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const makeTestId = useMakeTestId('DeviceEditDrawer');
  const { device, fetching, stale } = useDeviceDetails(id);

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [, updateAlexaDevice] = useMutation<
    ClientApi.AlexaDevice.Api.Update.Result,
    ClientApi.AlexaDevice.Api.Update.Variables
  >(ClientApi.AlexaDevice.Api.Update.Mutation);

  const onClose = () => {
    navigate('../');
  };

  const handleSuccess = () => {
    setIsSaving(false);
    navigate('../');
    dispatch(snackAdd({ message: `Device was successfully updated`, type: 'success' }));
  };

  const handleError = () => {
    setIsSaving(false);
    dispatch(snackAdd({ message: `Error updating device`, type: 'error' }));
  };

  const handleAlexaDeviceUpdate = (values: FormikValues) => {
    updateAlexaDevice({
      input: {
        id,
        name: values.name,
        // dndModeEnabled: values.dndModeEnabled,
        // maxVolume: values.maxVolume,
        // echoSpeakingRate: values.echoSpeakingRate,
        // settingsPolicyId: values.settingsPolicyId,
      },
    }).then((result) => {
      if (result.error || result.data?.success === false) {
        handleError();
      } else {
        handleSuccess();
      }
    });
  };

  const onFormSubmit = (values: FormikValues) => {
    setIsSaving(true);
    if (isAlexaDevice(device)) {
      handleAlexaDeviceUpdate(values);
    }
  };

  const initialValues = device
    ? {
        name: device.name ?? '',
        kind: device.kind ?? '',
        serialNumber: device.serialNumber,
        macAddress: device.macAddress,
        model: device.model,
        softwareVersion: device.softwareVersion,
        ...(isAlexaDevice(device) && {
          connectivity: device.connectivity,
        }),
        locationName: device.location?.name ?? '',
        // TODO: Add the fields back once we have the settings functioning
        // ...(isAlexaDevice(device) && {
        //   dndModeEnabled: device.dndModeEnabled ?? '',
        //   maxVolume: device.maxVolume ?? '',
        //   echoSpeakingRate: device.echoSpeakingRate ?? '',
        //   settingsPolicyId: device.settingsPolicyId ?? '',
        // }),
      }
    : {
        name: '',
        locationName: '',
        kind: '',
        serialNumber: '',
        macAddress: '',
        model: '',
        softwareVersion: '',
        connectivity: undefined,
      };

  const renderDrawerBody = ({ dirty, submitForm, isValid }: any) => {
    const enableSave = dirty && isValid;

    return (
      <>
        <Drawer.Header loading={fetching || stale}>
          <Box sx={headerInfoSx}>
            <Typography noWrap variant="h6">
              {`Edit device ${device?.name ? device?.name : ''}`}
            </Typography>
            {device?.serialNumber && device?.serialNumber}
          </Box>
        </Drawer.Header>
        <Drawer.Content>
          <Form disabled={fetching || stale}>
            {!device && fetching && <DeviceEditDrawerSkeleton />}
            {device && !fetching && <DeviceFields />}
          </Form>
        </Drawer.Content>
        <Drawer.Footer dataTestId={makeTestId('footer')}>
          <LoadingButton
            disabled={!enableSave}
            loading={isSaving}
            variant="contained"
            onClick={submitForm}
            data-testid={makeTestId('save')}
          >
            Save
          </LoadingButton>
        </Drawer.Footer>
      </>
    );
  };

  // If nothing is fetching and no device is found, navigate back to the devices page
  useEffect(() => {
    if (!fetching && !device) {
      navigate('../');
    }
  }, [fetching, device, navigate]);

  return (
    <FormikDrawer
      dataTestId={makeTestId('')}
      shouldConfirmClose
      initialValues={initialValues}
      validateForm={validateWithZod(validationSchema)}
      onClose={onClose}
      onSubmit={onFormSubmit}
    >
      {renderDrawerBody}
    </FormikDrawer>
  );
};

const headerInfoSx = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '11rem',
  flexGrow: 1,
};

export default DeviceEditDrawer;
