import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, IconButton } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { memo, MouseEvent, useCallback, useState } from 'react';

import ActionsMenu from './UserActionsMenu';

type ActionsCellProps = {
  params: GridRenderCellParams;
  dataTestId: string;
};

const ActionsCell = memo(({ params, dataTestId }: ActionsCellProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuOpen = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <Box sx={actionCellSx}>
      <IconButton
        data-testid={dataTestId}
        tabIndex={params.hasFocus ? 0 : -1}
        onClick={handleMenuOpen}
      >
        <MoreVertIcon />
      </IconButton>
      <ActionsMenu
        anchorEl={anchorEl}
        id={params.row.id}
        onClose={handleMenuClose}
        status={params.row.status}
        name={params.row.fullName}
        email={params.row.email}
        subtype={params.row.subtype}
        onActionSuccess={() => {}}
      />
    </Box>
  );
});

const actionCellSx = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'end',
  justifyContent: 'center',
  height: 'inherit',
};

export default ActionsCell;
