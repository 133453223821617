import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Typography,
} from '@mui/material';

import { ResetPasswordFormConfig, SerenityForm } from '@serenityapp/components-react-common';
import { Form, FormikField, useMakeTestId } from '@serenityapp/components-react-web';

type UnarchiveUserDialogProps = {
  dataTestId?: string;
  isOpen?: boolean;
  onClose: () => void;
  userName: string;
  email: string;
  onActionSuccess?: () => void;
};

const UnarchiveUserDialog = ({
  dataTestId,
  isOpen = true,
  onClose,
  userName,
  email,
  onActionSuccess,
}: UnarchiveUserDialogProps) => {
  const makeTestId = useMakeTestId('UnarchiveUserDialog', dataTestId);
  const unArchiveUserDialog = makeTestId('');

  const handleSubmit = () => {
    onClose();
    onActionSuccess?.();
  };

  return (
    <SerenityForm config={ResetPasswordFormConfig} onSubmit={handleSubmit}>
      {({ submitForm }) => (
        <Dialog
          fullWidth
          data-testid={unArchiveUserDialog}
          open={isOpen}
          onClick={(event) => event.stopPropagation()}
          onClose={onClose}
        >
          <DialogTitle>Unarchive user</DialogTitle>
          <DialogContent>
            <Typography>Are you sure you want to unarchive {userName}?</Typography>
            <List dense sx={listSx}>
              <ListItem sx={listItemSx}>Invitation email will be sent to {email}.</ListItem>
              <ListItem sx={listItemSx}>User account will be restored.</ListItem>
              <ListItem sx={listItemSx}>
                User will need to be re-added to any previous channels and groups.
              </ListItem>
            </List>
            <Alert severity="info" sx={alertSx}>
              <strong>Note: </strong>
              Upon next login the user will be prompted to change their password.
            </Alert>
            <Form>
              <FormikField
                autoFocus
                dataTestId={makeTestId('temporaryPassword')}
                helperText={ResetPasswordFormConfig.strings.helperText}
                name="temporaryPassword"
                variant="filled"
              />
            </Form>
          </DialogContent>
          <DialogActions>
            <Button data-testId={makeTestId('cancel')} onClick={onClose}>
              Cancel
            </Button>
            <Button data-testid={makeTestId('send')} variant="contained" onClick={submitForm}>
              Send
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </SerenityForm>
  );
};

const alertSx = {
  my: 2,
};

const listSx = {
  listStyle: 'inside',
};

const listItemSx = {
  display: 'list-item',
};

export default UnarchiveUserDialog;
