import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useMakeTestId } from '@serenityapp/components-react-web';
import { AssertFn, MimeLookupIsomorphic } from '@serenityapp/core';
import { userImport } from '@serenityapp/redux-store';

import AttachFile from '../../components/AttachFile';

const title = 'Import users';
const MimeLookup = new MimeLookupIsomorphic();

type ImportUsersDialogProps = {
  dataTestId?: string;
  /** Dialog is only rendered when isOpen is true */
  isOpen: boolean;
  onClose: () => void;
  onActionSuccess?: () => void;
};

const ImportUsersDialog = ({ dataTestId, isOpen, onClose }: ImportUsersDialogProps) => {
  const makeTestId = useMakeTestId('ImportUsersDialog', dataTestId);
  const ImportUsersDialogTestId = makeTestId('');
  const [file, setFile] = useState<File | undefined | null>(null);

  const dispatch = useDispatch();

  const handleCancel = () => {
    setFile(undefined);
    onClose();
  };

  const handleImport = () => {
    AssertFn.isNotNullOrUndefined(file, filename, 'file');
    const url = URL.createObjectURL(file);

    dispatch(
      userImport({
        file: {
          key: url,
          name: file.name,
          size: file.size,
          type: file.type ? file.type : MimeLookup.lookup(file.name),
          uri: url,
        },
      }),
    );
    setFile(undefined);
    onClose();
  };

  const handleFileAttached = (file: File | undefined | null) => {
    setFile(file);
  };

  return (
    <Dialog
      fullWidth
      data-testid={ImportUsersDialogTestId}
      open={isOpen}
      onClick={(event) => event.stopPropagation()}
      onClose={handleCancel}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography>
          Add or edit users by importing an Excel or CSV file with their information
        </Typography>
        <AttachFile file={file} sx={attachFileSx} onFileAttached={handleFileAttached} />
      </DialogContent>
      <DialogActions>
        <Button data-testid={makeTestId('cancel')} onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          data-testid={makeTestId('import')}
          disabled={!file}
          variant="contained"
          onClick={handleImport}
        >
          Import
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const attachFileSx = {
  my: 2,
};

export default ImportUsersDialog;
const filename = 'ImportUsersDialog.tsx';
