import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from 'urql';
import { useState } from 'react';

import {
  ChannelCreateEditFormValues,
  SelectorItemProps,
} from '@serenityapp/components-react-common';
import { RouteParamId } from '@serenityapp/domain';
import { snackAdd } from '@serenityapp/redux-store';
import { useMakeTestId } from '@serenityapp/components-react-web';
import { ClientApi } from '@serenityapp/api-client-graph';
import {
  useChannelDetails,
  useOrganizationDetails,
  selectorItemPropsToMembershipInput,
} from '@serenityapp/client-data';

import { ChannelCreateEditForm } from './components';

const ChannelEditDrawer = () => {
  const makeTestId = useMakeTestId('ChannelEditDrawer');
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [, updateChannel] = useMutation<
    ClientApi.Conversation.Api.Update.MutationResult,
    ClientApi.Conversation.Api.Update.Variables
  >(ClientApi.Conversation.Api.Update.Mutation);

  const { id } = useParams<RouteParamId>() as RouteParamId;
  const {
    channel,
    fetching,
    stale,
    administrableContactsAsSelectorItems,
    administrableLocationsAsSelectorItems,
    administrableStaffAsSelectorItems,
    isSharedChannel,
    isViewersOrgOwner,
  } = useChannelDetails(id);

  const { organization, fetching: fetchingOrganization } = useOrganizationDetails(channel?.orgId);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const ownershipInfo =
    !fetchingOrganization && organization?.name
      ? `Shared channel (owner ${organization?.name})`
      : `Shared channel`;

  const initialValues = {
    name: channel?.name ?? '',
    description: channel?.description ?? '',
    familyAndFriends: isViewersOrgOwner ? administrableContactsAsSelectorItems : [],
    locations: administrableLocationsAsSelectorItems,
    staff: administrableStaffAsSelectorItems,
    isOneWayChannel: channel?.isOneWayChannel ?? false,
  };

  const goBack = () => navigate('..');

  const handleFormSubmit = (values: ChannelCreateEditFormValues) => {
    setIsSubmitting(true);

    const input = {
      id,
      name: values.name?.trim(),
      description: values.description?.trim(),
      staff: selectorItemPropsToMembershipInput(values.staff as Array<SelectorItemProps>, id),
      familyAndFriends: selectorItemPropsToMembershipInput(
        values.familyAndFriends as Array<SelectorItemProps>,
        id,
      ),
      locations: (values.locations as Array<SelectorItemProps>)?.map((item) => item.id),
      isOneWayChannel: values.isOneWayChannel,
    };

    updateChannel({
      input,
    }).then((result) => {
      setIsSubmitting(false);
      if (result.error || result.data?.result?.success === false) {
        dispatch(snackAdd({ message: 'Error updating channel', type: 'error' }));
      } else {
        goBack();
        dispatch(snackAdd({ message: 'Channel successfully updated', type: 'success' }));
      }
    });
  };

  return (
    <ChannelCreateEditForm
      isEditMode
      conversationId={channel?.id}
      conversationName={channel?.name}
      dataTestId={makeTestId('')}
      handleFormClose={goBack}
      handleFormSubmit={handleFormSubmit}
      initialValues={initialValues}
      isLoading={fetching || stale}
      isViewersOrgOwner={isViewersOrgOwner}
      isSaving={isSubmitting}
      ownershipInfo={isSharedChannel ? ownershipInfo : undefined}
      title={`Edit channel - ${channel?.name}`}
    />
  );
};

export default ChannelEditDrawer;
