import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from 'urql';

import { ClientApi } from '@serenityapp/api-client-graph';
import { useMakeTestId } from '@serenityapp/components-react-web';
import { snackAdd } from '@serenityapp/redux-store';

type ArchiveUserDialogProps = {
  dataTestId?: string;
  isOpen?: boolean;
  onClose: () => void;
  subtype: string;
  userName: string;
  id: string;
  onActionSuccess?: () => void;
};

const ArchiveUserDialog = ({
  dataTestId,
  isOpen = true,
  onClose,
  subtype,
  userName,
  id,
  onActionSuccess,
}: ArchiveUserDialogProps) => {
  const makeTestId = useMakeTestId('ArchiveUserDialog', dataTestId);
  const archiveUserDialog = makeTestId('');

  const [isArchiving, setIsArchiving] = useState(false);

  const isResident = subtype === 'resident';

  const dispatch = useDispatch();

  const [, archiveUser] = useMutation<
    ClientApi.User.Api.Archive.MutationResult,
    ClientApi.User.Api.Archive.Variables
  >(ClientApi.User.Api.Archive.Mutation);

  const onFailed = () => {
    dispatch(snackAdd({ message: 'Error archiving user', type: 'error' }));
    onClose();
  };

  const onSuccess = () => {
    dispatch(snackAdd({ message: 'User successfully archived', type: 'success' }));
    onClose();
    onActionSuccess?.();
  };

  const handleConfirmClick = () => {
    setIsArchiving(true);
    archiveUser({
      input: {
        id,
      },
    }).then((result) => {
      setIsArchiving(false);
      if (result.error || result.data?.result?.success === false) {
        onFailed();
      } else {
        onSuccess();
      }
    });
  };

  return (
    <Dialog
      fullWidth
      data-testid={archiveUserDialog}
      open={isOpen}
      onClick={(event) => event.stopPropagation()}
      onClose={onClose}
    >
      <DialogTitle>Archive user</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to archive {userName}?</Typography>
        {!isResident && (
          <List dense sx={listSx}>
            <ListItem sx={listItemSx}>Users will no longer be able to login.</ListItem>
            <ListItem sx={listItemSx}>User will be removed from all channels.</ListItem>
            <ListItem sx={listItemSx}>Existing messages will be preserved.</ListItem>
          </List>
        )}
        {isResident && (
          <List dense sx={listSx}>
            <ListItem sx={listItemSx}>
              Resident channel for {userName} will also be removed.
            </ListItem>
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <Button data-testid={makeTestId('cancel')} disabled={isArchiving} onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          data-testid={makeTestId('confirm')}
          loading={isArchiving}
          variant="contained"
          onClick={handleConfirmClick}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const listSx = {
  listStyle: 'inside',
};

const listItemSx = {
  display: 'list-item',
};

export default ArchiveUserDialog;
