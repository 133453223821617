import type { GridSortCellParams } from '@mui/x-data-grid-pro';

export const DEFAULT_ADMIN_DATA_GRID_PAGE_SIZE = 25;
export const ADMIN_DATA_GRID_PAGE_SIZE_OPTIONS = [DEFAULT_ADMIN_DATA_GRID_PAGE_SIZE, 50, 100];

/**
 * Sort comparator for string columns potentially containing numeric values in the admin data grid.
 *
 * @param a - The first string to compare.
 * @param b - The second string to compare.
 * @param cellParamsA - The first cell params.
 * @param cellParamsB - The second cell params.
 * @returns The result of the comparison.
 */
export function gridNumericAwareStringColComparator(
  a: string | null,
  b: string | null,
  _cellParamsA: GridSortCellParams<string>,
  _cellParamsB: GridSortCellParams<string>,
) {
  if (a === null && b === null) return 0;
  if (a === null) return -1;
  if (b === null) return 1;

  return a.localeCompare(b, 'en-US', { numeric: true });
}
