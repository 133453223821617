import { useState } from 'react';
import { useSelector } from 'react-redux';

import { BasicMenu, BasicMenuItemProps, useMakeTestId } from '@serenityapp/components-react-web';
import { UserStatus, WithKey } from '@serenityapp/domain';
import { getCurrentUserId } from '@serenityapp/redux-store';

import ArchiveUserDialog from './ArchiveUserDialog';
import DisableUserDialog from './DisableUserDialog';
import ReactivateAccountDialog from './ReactivateAccountDialog';
import ResetPasswordDialog from './ResetPasswordDialog';
import SendInvitationDialog from './SendInvitationDialog';
import SendInvitationErrorDialog from './SendInvitationErrorDialog';
import UnarchiveUserDialog from './UnarchiveUserDialog';

type UserActionsMenuProps = {
  status: string;
  name: string;
  id: string;
  email: string;
  subtype: string;
  anchorEl: HTMLElement | null;
  onClose?: () => void;
  onActionSuccess?: () => void;
};

const UserActionsMenu = ({
  anchorEl,
  status,
  name,
  id,
  email,
  subtype,
  onClose,
  onActionSuccess,
}: UserActionsMenuProps) => {
  const makeTestId = useMakeTestId('UsersActionsMenu');

  const currentUserId = useSelector(getCurrentUserId);
  const isCurrentUser = currentUserId === id;

  const [isResetPasswordDialogOpen, setIsResetPasswordDialogOpen] = useState<boolean>(false);
  const [isSendInvitationDialogOpen, setIsSendInvitationDialogOpen] = useState<boolean>(false);
  const [isSendInvitationErrorDialogOpen, setIsSendInvitationErrorDialogOpen] =
    useState<boolean>(false);
  const [isReactivateUserDialogOpen, setIsReactivateDialogOpen] = useState<boolean>(false);
  const [isDisableUserDialogOpen, setIsDisableUserDialogOpen] = useState<boolean>(false);
  const [isArchiveUserDialogOpen, setIsArchiveUserDialogOpen] = useState<boolean>(false);
  const [isUnarchiveUserDialogOpen, setIsUnarchiveUserDialogOpen] = useState<boolean>(false);

  const openUserActionsMenuAnchor = Boolean(anchorEl);

  const handleCloseMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    onClose?.();
  };

  const userActionsMenuSections: Array<Array<WithKey<BasicMenuItemProps>>> = [];

  if (status === UserStatus.NOT_AVAILABLE) {
    userActionsMenuSections.push([
      {
        key: 'send-invite',
        dataTestId: makeTestId('send-invite'),
        onClick: () =>
          email ? setIsSendInvitationDialogOpen(true) : setIsSendInvitationErrorDialogOpen(true),
        label: 'Send invite',
        handleCloseMenu,
      },
    ]);
  }

  if (status === UserStatus.DISABLED) {
    userActionsMenuSections.push([
      {
        key: 'activate',
        dataTestId: makeTestId('activate'),
        onClick: () => setIsReactivateDialogOpen(true),
        label: 'Activate account',
        handleCloseMenu,
      },
    ]);
  }

  if (status === UserStatus.ARCHIVED) {
    userActionsMenuSections.push([
      {
        key: 'unarchive',
        dataTestId: makeTestId('unarchive'),
        onClick: () => setIsUnarchiveUserDialogOpen(true),
        label: 'Unarchive user',
        handleCloseMenu,
      },
    ]);
  }

  if (status === UserStatus.INVITED || status === UserStatus.EXPIRED) {
    userActionsMenuSections.push([
      {
        key: 'resend-invitation',
        dataTestId: makeTestId('resend-invitation'),
        onClick: () => setIsSendInvitationDialogOpen(true),
        label: 'Resend invitation',
        handleCloseMenu,
      },
    ]);
  }

  if (
    status === UserStatus.ACTIVE ||
    status === UserStatus.PASSWORD_EXPIRED ||
    status === UserStatus.PASSWORD_RESET
  ) {
    userActionsMenuSections.push([
      {
        key: 'disable-user',
        dataTestId: makeTestId('disable-user'),
        onClick: () => setIsDisableUserDialogOpen(true),
        label: 'Disable user',
        handleCloseMenu,
        disabled: isCurrentUser,
        tooltip: isCurrentUser ? "Can't disable yourself" : '',
      },
      {
        key: 'reset-password',
        dataTestId: makeTestId('reset-password'),
        onClick: () => setIsResetPasswordDialogOpen(true),
        label: 'Reset password',
        handleCloseMenu,
      },
    ]);
  }

  if (status !== UserStatus.ARCHIVED) {
    userActionsMenuSections.push([
      {
        key: 'archive-user',
        dataTestId: makeTestId('archive-user'),
        onClick: () => setIsArchiveUserDialogOpen(true),
        label: 'Archive user',
        disabled: isCurrentUser,
        tooltip: isCurrentUser ? "Can't archive yourself" : '',
      },
    ]);
  }

  return (
    <>
      {anchorEl && (
        <BasicMenu
          key="basic-menu"
          keepMounted
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          dataTestId={makeTestId('')}
          open={openUserActionsMenuAnchor}
          sections={userActionsMenuSections}
          onClose={handleCloseMenu}
        />
      )}
      {isResetPasswordDialogOpen && (
        <ResetPasswordDialog
          email={email}
          userId={id}
          userName={name}
          onClose={() => setIsResetPasswordDialogOpen(false)}
        />
      )}
      {isSendInvitationDialogOpen && (
        <SendInvitationDialog
          email={email}
          isResend={status !== UserStatus.NOT_AVAILABLE}
          userId={id}
          userName={name}
          onClose={() => setIsSendInvitationDialogOpen(false)}
        />
      )}
      {isSendInvitationErrorDialogOpen && (
        <SendInvitationErrorDialog
          userName={name}
          onClose={() => setIsSendInvitationErrorDialogOpen(false)}
        />
      )}
      {isReactivateUserDialogOpen && (
        <ReactivateAccountDialog
          userId={id}
          userName={name}
          onClose={() => setIsReactivateDialogOpen(false)}
        />
      )}
      {isArchiveUserDialogOpen && (
        <ArchiveUserDialog
          id={id}
          subtype={subtype}
          userName={name}
          onActionSuccess={onActionSuccess}
          onClose={() => setIsArchiveUserDialogOpen(false)}
        />
      )}
      {isDisableUserDialogOpen && (
        <DisableUserDialog
          userId={id}
          userName={name}
          onClose={() => setIsDisableUserDialogOpen(false)}
        />
      )}
      {isUnarchiveUserDialogOpen && (
        <UnarchiveUserDialog
          email={email}
          userName={name}
          onActionSuccess={onActionSuccess}
          onClose={() => setIsUnarchiveUserDialogOpen(false)}
        />
      )}
    </>
  );
};

export default UserActionsMenu;
