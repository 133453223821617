import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from 'urql';

import { ClientApi } from '@serenityapp/api-client-graph';
import { useMakeTestId } from '@serenityapp/components-react-web';
import { snackAdd } from '@serenityapp/redux-store';

type ReactivateAccountDialogProps = {
  dataTestId?: string;
  isOpen?: boolean;
  onClose: () => void;
  userName: string;
  userId: string;
  onActionSuccess?: () => void;
};

const ReactivateAccountDialog = ({
  dataTestId,
  isOpen = true,
  onClose,
  userName,
  userId,
  onActionSuccess,
}: ReactivateAccountDialogProps) => {
  const makeTestId = useMakeTestId('ReactivateAccountDialog', dataTestId);
  const reactivateUserDialog = makeTestId('');
  const dispatch = useDispatch();

  const [isReactivateAccountLoading, setIsReactivateAccountLoading] = useState(false);

  const [, accountEnable] = useMutation<
    ClientApi.User.Api.AccountEnable.MutationResult,
    ClientApi.User.Api.AccountEnable.Variables
  >(ClientApi.User.Api.AccountEnable.Mutation);

  const handleSubmit = async () => {
    setIsReactivateAccountLoading(true);
    const variables = {
      input: {
        userId,
      },
    };
    accountEnable(variables).then((result) => {
      setIsReactivateAccountLoading(false);
      if (result.error || result.data?.result?.success === false) {
        dispatch(snackAdd({ message: 'Error activating user account', type: 'error' }));
        onClose();
      } else {
        dispatch(snackAdd({ message: 'User account successfully activated', type: 'success' }));
        onClose();
        onActionSuccess?.();
      }
    });
  };

  return (
    <Dialog
      fullWidth
      data-testid={reactivateUserDialog}
      open={isOpen}
      onClick={(event) => event.stopPropagation()}
      onClose={onClose}
    >
      <DialogTitle>Re-activate account</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to re-activate {userName}?</Typography>
        <List dense sx={listSx}>
          <ListItem sx={listItemSx}>Users ability to login will be resumed.</ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid={makeTestId('cancel')}
          disabled={isReactivateAccountLoading}
          onClick={onClose}
        >
          Cancel
        </Button>
        <LoadingButton
          data-testid={makeTestId('confirm')}
          loading={isReactivateAccountLoading}
          variant="contained"
          onClick={handleSubmit}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const listSx = {
  listStyle: 'inside',
};

const listItemSx = {
  display: 'list-item',
};

export default ReactivateAccountDialog;
