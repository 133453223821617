import { memo } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';
import { ConversationParamId, S3FileInput } from '@serenityapp/domain';
import { MessagesListItemProps } from '@serenityapp/components-react-common';
import {
  MessageBlocks,
  InlineToolbarToolProps,
  Message,
  NewMessageIndicator,
  FileGroup,
} from '@serenityapp/components-react-web';
import { StringFn, convertBase64toImageSrc, MimeFn } from '@serenityapp/core';
import { messagesResend } from '@serenityapp/redux-store';
import {
  getFilesFromMessagePartItems,
  useArchiveTool,
  useCopyTool,
  useDeleteTool,
  useMarkUnreadTool,
  usePinTool,
} from './utils';
import { downloadFile } from '../../../../utils';
import LexicalText from './LexicalText';
import LoadingAnimation from './LoadingAnimation';

type MessageItemProps = MessagesListItemProps & {
  onMarkMessageUnread?: (messageId: string) => void;
  hasNewMessageIndicator?: boolean;
  shouldDeprecateUsernames?: boolean;
};

const MessageItem = ({
  id,
  message,
  blocks,
  pinned = false,
  time,
  sender,
  canPin = false,
  canArchive = false,
  readReceipts,
  sendFailed,
  isOptimistic,
  showToolsMenu = true,
  showPinnedTag = true,
  hasNewMessageIndicator,
  dense,
  messagePartItems = [],
  onMarkMessageUnread,
  loading,
  shouldDeprecateUsernames,
}: MessageItemProps) => {
  const { conversationId } = useParams<ConversationParamId>() as ConversationParamId;
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const navigate = useNavigate();

  const messageTime = time ?? '';
  const messageText = message?.trim();

  const senderName = shouldDeprecateUsernames ? sender?.fullName || '' : sender?.name || '';
  const senderInitials = StringFn.initials(sender?.fullName || sender?.name || '');
  const senderAvatarSrc = sender?.avatar?.url || convertBase64toImageSrc(sender?.avatar?.preview);

  const navigateToFileViewer = (id: string, key: string) => {
    const options = { state: { prevPage: pathname, messagePartItems, sender } };
    navigate(`file-viewer/${id}/${key}`, options);
  };

  const handleRetryClick = () => {
    dispatch(messagesResend({ conversationId, messageId: id }));
  };

  const handleFileClick = ({ key, contentType, url, contentName }: S3FileInput) => {
    const isFileSupportedByFileViewer =
      MimeFn.isImage(contentType) || MimeFn.isVideo(contentType);

    if (isFileSupportedByFileViewer) {
      navigateToFileViewer(id, key);
      return;
    }

    downloadFile(url, contentName);
  };

  const attachments = getFilesFromMessagePartItems(
    messagePartItems,
    handleFileClick,
    !!isOptimistic,
  );

  const pinTool = usePinTool(conversationId, id, pinned, canPin, sendFailed);
  const archiveTool = useArchiveTool(conversationId, id, canArchive, sendFailed);
  const copyTool = useCopyTool(message, sendFailed);
  const markUnreadTool = useMarkUnreadTool(id, onMarkMessageUnread, sendFailed, loading);
  const deleteTool = useDeleteTool(conversationId, id, sendFailed);
  const actionTools = R.reject(R.isNil, [
    deleteTool,
    copyTool,
    pinTool,
    archiveTool,
    markUnreadTool,
  ]) as Array<InlineToolbarToolProps>;

  const renderContent = () => {
    if (loading) {
      return <LoadingAnimation />;
    }

    if (blocks) {
      return <MessageBlocks data={blocks} />;
    }

    if (messageText) {
      return <LexicalText text={messageText} />;
    }

    return null;
  };

  return (
    <>
      {hasNewMessageIndicator && <NewMessageIndicator hidden={false} />}
      <Message
        actionTools={actionTools}
        dense={dense}
        failed={sendFailed}
        handleRetryClick={handleRetryClick}
        initials={senderInitials}
        isOptimistic={isOptimistic}
        name={senderName}
        pinned={pinned}
        readReceipts={readReceipts}
        showPinnedTag={showPinnedTag}
        showToolsMenu={showToolsMenu}
        src={senderAvatarSrc}
        time={messageTime}
      >
        {renderContent()}
        <FileGroup files={attachments} sx={fileGroupSx} />
      </Message>
    </>
  );
};

export const fileGroupSx = {
  mt: 1,
};

export default memo(
  MessageItem,
  (prevProps, nextProps) =>
    prevProps.pinned === nextProps.pinned &&
    prevProps.readReceipts?.length === nextProps.readReceipts?.length &&
    prevProps.sendFailed === nextProps.sendFailed &&
    prevProps.canArchive === nextProps.canArchive &&
    prevProps.canPin === nextProps.canPin &&
    prevProps.canSavePhoto === nextProps.canSavePhoto &&
    prevProps.sender?.avatar?.url === nextProps.sender?.avatar?.url &&
    prevProps.sender?.avatar?.preview === nextProps.sender?.avatar?.preview &&
    prevProps.sender?.fullName === nextProps.sender?.fullName &&
    prevProps.sender?.initials === nextProps.sender?.initials &&
    prevProps.hasNewMessageIndicator === nextProps.hasNewMessageIndicator &&
    prevProps.onMarkMessageUnread === nextProps.onMarkMessageUnread &&
    prevProps.blocks === nextProps.blocks &&
    prevProps.message === nextProps.message,
);
