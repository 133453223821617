import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'urql';

import { ClientApi } from '@serenityapp/api-client-graph';
import { useCurrentUser } from '@serenityapp/client-data';
import { UserCreateEditFormValues } from '@serenityapp/components-react-common';
import { useMakeTestId } from '@serenityapp/components-react-web';
import { AssertFn, EnumFn, PhoneNumberFn, ServiceLevel, StringFn } from '@serenityapp/core';
import { IdFn } from '@serenityapp/core-id';
import {
  ConversationKind,
  ConversationSubjectKind,
  UserAccountCreateOperation,
  UserCreateInputNext,
  UserErrorMessages,
  UserType,
  UserTypeFn,
} from '@serenityapp/domain';
import { snackAdd } from '@serenityapp/redux-store';

import UserCreateEditForm from './components/UserCreateEditForm';

const DISPLAY_NAME = 'UserCreateDrawer';

const UserCreateDrawer = () => {
  const makeTestId = useMakeTestId('UserCreateDrawer');
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [emailError, setEmailError] = useState(false);

  const currentUser = useCurrentUser();
  const orgId = currentUser?.user?.orgId;

  const goBack = () => navigate('..');

  const [, createUser] = useMutation<
    ClientApi.User.Api.CreateNext.MutationResult,
    ClientApi.User.Api.CreateNext.Variables
  >(ClientApi.User.Api.CreateNext.Mutation);

  const handleFormSubmit = ({
    department,
    description,
    email,
    jobTitle,
    firstName,
    lastName,
    phoneNumber,
    userType,
    workerCategory,
    generalChannels,
    createAccount,
    sendInvitationEmail,
    invitationSender,
    staff,
    residentChannels,
    familyAndFriends,
    locations,
    temporaryPassword,
    checkIn,
    serviceLevel,
    room,
  }: UserCreateEditFormValues) => {
    const isUserResident = UserTypeFn.isResident(userType);
    const userKind = UserTypeFn.toKind(userType as UserType);
    const isCreateAccount = !isUserResident && createAccount;
    const creator = IdFn.isValid(invitationSender)
      ? { userId: invitationSender as string }
      : undefined;
    const userId = IdFn.new();
    const residentConversationId = IdFn.new();
    const memberRoleId = IdFn.fromString('member');

    const selectedGeneralChannels = isUserResident ? [] : generalChannels;
    const selectedResidentChannels = isUserResident ? [] : residentChannels;

    const selectedLocations = !isUserResident ? [] : locations;
    const selectedFamilyAndFriends = !isUserResident ? [] : familyAndFriends;
    const selectedStaff = !isUserResident ? [] : staff;

    const isInvitation = isCreateAccount && sendInvitationEmail;

    const channelsInput = [
      ...(selectedResidentChannels || []),
      ...(selectedGeneralChannels || []),
    ].map(({ id }) => ({ conversationId: id }));

    const locationsInput = (selectedLocations || []).map(({ id }) => ({ locationId: id }));

    const trimmedTempPassword = temporaryPassword && temporaryPassword.trim();
    const finalTempPassword =
      !isUserResident && trimmedTempPassword && trimmedTempPassword.length > 0
        ? trimmedTempPassword
        : undefined;

    let operation = UserAccountCreateOperation.NONE;
    if (isCreateAccount) operation = UserAccountCreateOperation.CREATE;
    if (isInvitation) operation = UserAccountCreateOperation.INVITE;

    AssertFn.isDefined(currentUser, DISPLAY_NAME, 'currentUser');

    const fullName = `${firstName?.trim()} ${lastName?.trim()}`;

    // This component is used when org has `removeUsernames` feature flag enabled.
    // In this case, in form, we only have input fields for `firstName` and `lastName`.
    // We can't set userName thru this form, which is correct, but some older organizations
    // still use usernames as a primary user identifier, so we need to at least programmatically
    // set it up.

    // We had an issue in cross org where one org had `removeUsernames` feature flag enabled and
    // another one didn't. This meant when a name was changed in the org with `removeUsernames`
    // the userName won't update, but will still be displayed in the org that didn't have the flag.
    const userName = fullName.toLocaleLowerCase().split(' ').join('.');

    const input: UserCreateInputNext = {
      id: userId,
      account: {
        operation,
        password: finalTempPassword,
      },
      conversations: channelsInput,
      creator,
      // TODO: [{groupId, userId}]
      groups: [],
      checkInOptOut: !checkIn,
      serviceLevel: !serviceLevel ? ServiceLevel.NONE : EnumFn.parse(ServiceLevel, serviceLevel),
      roomId: room,
      organizations: [
        {
          orgId,
          kind: userKind,

          department: department ? department?.trim() : undefined,
          description: description?.trim(),
          jobTitle: jobTitle ? jobTitle?.trim() : undefined,
          workerCategory: workerCategory ? workerCategory?.trim() : undefined,
        },
      ],
      profile: {
        email,
        phoneNumber: PhoneNumberFn.getInternationalNumber(phoneNumber),
        fullName,
        firstName: firstName?.trim(),
        lastName: lastName?.trim(),
        userName: isUserResident ? fullName : userName,
        initials: StringFn.initials(fullName),
      },
      resident: {
        locations: locationsInput,
        staff: selectedStaff?.map((item) => ({
          conversationId: residentConversationId,
          kind: ConversationKind.CHANNEL,
          roleId: memberRoleId,
          subject: ConversationSubjectKind.RESIDENT,
          userId: item.id,
        })),
        familyAndFriends: selectedFamilyAndFriends?.map((item) => ({
          conversationId: residentConversationId,
          kind: ConversationKind.CHANNEL,
          roleId: memberRoleId,
          subject: ConversationSubjectKind.RESIDENT,
          userId: item.id,
        })),
      },
    };

    setIsSubmitting(true);

    createUser({ input }).then((result) => {
      setIsSubmitting(false);
      if (result.error || result.data?.result?.success === false) {
        dispatch(snackAdd({ message: 'Error adding user', type: 'error' }));
        if (result.data?.result?.msg === UserErrorMessages.EMAIL_ALREADY_EXISTS) {
          setEmailError(true);
        }
      } else {
        dispatch(snackAdd({ message: 'User successfully added', type: 'success' }));
        goBack();
      }
    });
  };

  return (
    <UserCreateEditForm
      dataTestId={makeTestId('')}
      handleFormClose={goBack}
      handleFormSubmit={handleFormSubmit}
      isSaving={isSubmitting}
      title="Create new user"
      emailError={emailError}
    />
  );
};

UserCreateDrawer.displayName = DISPLAY_NAME;

export default UserCreateDrawer;
