import {
  DownloadOptions,
  DownloadProgressCallback,
  DownloadResumable,
  FileSystemEncoding,
  FileSystemUploadOptions,
} from '@serenityapp/core';

export const FileSystemWeb = {
  readAsBase64(path: string): Promise<string> {
    throw new Error('Method not implemented.');
  },
  readAsJson<T = any>(path: string): Promise<T | undefined> {
    throw new Error('Method not implemented.');
  },
  readAsString(path: string): Promise<string> {
    throw new Error('Method not implemented.');
  },
  copy(source: string, dest: string): Promise<void> {
    throw new Error('Method not implemented.');
  },
  createDownloadResumable(
    uri: string,
    fileUri: string,
    options?: DownloadOptions,
    callback?: DownloadProgressCallback,
    resumeData?: string,
  ): DownloadResumable {
    throw new Error('Method not implemented.');
  },
  downloadAsync(
    url: string,
    fileUri: string,
    callback?: DownloadProgressCallback,
  ): Promise<void> {
    throw new Error('Method not implemented.');
  },
  ensureDir(dir: string): Promise<void> {
    throw new Error('Method not implemented.');
  },
  exists(path: string): Promise<boolean> {
    throw new Error('Method not implemented.');
  },
  getFreeDiskStorageAsync(): Promise<number> {
    return Promise.resolve(0);
  },
  isDownloadResumableSupported(): boolean {
    throw new Error('Method not implemented.');
  },
  remove(path: string, options?: { idempotent?: boolean | undefined }): Promise<void> {
    throw new Error('Method not implemented.');
  },
  move(source: string, dest: string, options: { overwrite: boolean }): Promise<void> {
    throw new Error('Method not implemented.');
  },
  read(path: string, encoding: FileSystemEncoding): Promise<string> {
    throw new Error('Method not implemented.');
  },
  readdir(path: string): Promise<readonly string[]> {
    throw new Error('Method not implemented.');
  },
  readAsBuffer(path: string): Promise<Buffer> {
    throw new Error('Method not implemented.');
  },
  uploadAsync(url: string, fileUri: string, options?: FileSystemUploadOptions): Promise<void> {
    throw new Error('Method not implemented.');
  },
  writeBuffer(path: string, data: Buffer): Promise<void> {
    throw new Error('Method not implemented.');
  },
  writeString(path: string, data: string): Promise<void> {
    throw new Error('Method not implemented.');
  },
  clearCacheDirectory(): Promise<void> {
    throw new Error('Method not implemented.');
  },
  clearDocumentDirectory(): Promise<void> {
    throw new Error('Method not implemented.');
  },
  getCacheDirectorySize(): Promise<number> {
    throw new Error('Method not implemented.');
  },
  getCacheSizeFromDocumentDirectory(): Promise<number> {
    throw new Error('Method not implemented.');
  },
};
