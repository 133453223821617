import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from 'urql';

import { ClientApi } from '@serenityapp/api-client-graph';
import {
  ResendInvitationFormConfig,
  ResendInvitationFormValues,
  SerenityForm,
} from '@serenityapp/components-react-common';
import { Form, FormikField, useMakeTestId } from '@serenityapp/components-react-web';
import { snackAdd } from '@serenityapp/redux-store';

type SendInvitationDialogProps = {
  dataTestId?: string;
  isOpen?: boolean;
  onClose: () => void;
  userName: string;
  email: string;
  isResend: boolean;
  userId: string;
  onActionSuccess?: () => void;
};

const SendInvitationDialog = ({
  dataTestId,
  isOpen = true,
  onClose,
  userName,
  email,
  isResend,
  userId,
  onActionSuccess,
}: SendInvitationDialogProps) => {
  const makeTestId = useMakeTestId('SendInvitationDialog', dataTestId);
  const SendInvitationDialog = makeTestId('');
  const dispatch = useDispatch();

  const [isResendInvitationLoading, setIsResendInvitationLoading] = useState(false);

  const title = isResend ? 'Resend invitation' : 'Send invitation';

  const [, resendInvitation] = useMutation<
    ClientApi.User.Api.ResendInvitation.MutationResult,
    ClientApi.User.Api.ResendInvitation.Variables
  >(ClientApi.User.Api.ResendInvitation.Mutation);

  const handleSubmit = async ({ temporaryPassword }: ResendInvitationFormValues) => {
    const input = {
      id: userId,
      temporaryPassword: temporaryPassword ?? 'Serenity!',
    };

    setIsResendInvitationLoading(true);

    resendInvitation({
      input,
    }).then((result) => {
      setIsResendInvitationLoading(false);
      if (result.error || result.data?.result?.success === false) {
        dispatch(
          snackAdd({
            message: isResend ? 'Error resending invitation' : 'Error sending invitation',
            type: 'error',
          }),
        );
        onClose();
      } else {
        dispatch(
          snackAdd({
            message: isResend ? 'Invitation successfully resent' : 'Invitation successfully sent',
            type: 'success',
          }),
        );
        onClose();
        onActionSuccess?.();
      }
    });
  };

  return (
    <SerenityForm config={ResendInvitationFormConfig} onSubmit={handleSubmit}>
      {({ submitForm }) => (
        <Dialog
          fullWidth
          data-testid={SendInvitationDialog}
          open={isOpen}
          onClick={(event) => event.stopPropagation()}
          onClose={onClose}
        >
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <Typography>
              An invitation will be sent by email to {userName} at {email} with login information.
            </Typography>
            <Alert severity="info" sx={alertSx}>
              <strong>Note: </strong>
              Upon next login the user will be prompted to change their password.
            </Alert>
            <Form disabled={isResendInvitationLoading}>
              <FormikField
                autoFocus
                dataTestId={makeTestId('temporaryPassword')}
                name="temporaryPassword"
                variant="filled"
              />
            </Form>
          </DialogContent>
          <DialogActions>
            <Button
              data-testid={makeTestId('cancel')}
              disabled={isResendInvitationLoading}
              onClick={onClose}
            >
              Cancel
            </Button>
            <LoadingButton
              data-testid={makeTestId('send')}
              loading={isResendInvitationLoading}
              variant="contained"
              onClick={submitForm}
            >
              Send
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </SerenityForm>
  );
};

const alertSx = {
  my: 2,
};

export default SendInvitationDialog;
