import { Add } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridRowProps,
} from '@mui/x-data-grid-pro';
import { useCallback, useMemo } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { useFilters } from '@serenityapp/client-data';
import { AdministrationHeader, useMakeTestId, View } from '@serenityapp/components-react-web';

import { AdminDataGrid } from '../components';
import CustomGridRow from '../components/CustomGridRow';
import { useAdminBreadcrumbs } from '../hooks';
import {
  ADMIN_DATA_GRID_PAGE_SIZE_OPTIONS,
  DEFAULT_ADMIN_DATA_GRID_PAGE_SIZE,
  gridNumericAwareStringColComparator,
} from '../utils';
import { ActionsCell, QuickSearchToolbarFilters } from './components';

const FiltersPage = () => {
  const title = 'Filters categories';
  const makeTestId = useMakeTestId('FiltersPage');
  const mainTestId = makeTestId('');

  const { filters, fetching } = useFilters();

  const navigate = useNavigate();

  const crumbs = useAdminBreadcrumbs(title);

  const goToResourceDetails = (params: GridRowParams) => {
    const { id } = params;
    navigate(id.toString());
  };

  const goToResourceCreate = () => navigate('create');

  const addButton = (
    <Button
      key="add-button"
      data-testid={makeTestId('add')}
      startIcon={<Add />}
      variant="contained"
      onClick={goToResourceCreate}
    >
      New
      <Box component="span" sx={longButtonTitleSx}>
        &nbsp;filter
      </Box>
    </Button>
  );

  const actionsCellRenderer = useCallback(
    (params: GridRenderCellParams) => <ActionsCell params={params} makeTestId={makeTestId} />,
    [makeTestId],
  );

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        sortable: true,
        type: 'string',
        sortComparator: gridNumericAwareStringColComparator,
      },
      {
        field: 'description',
        headerName: 'Description',
        flex: 1,
        sortable: false,
        type: 'string',
        sortComparator: gridNumericAwareStringColComparator,
      },
      {
        field: 'id',
        headerName: 'Actions',
        width: 80,
        disableColumnMenu: true,
        disableColumnSelector: true,
        sortable: false,
        renderCell: actionsCellRenderer,
        filterable: false,
        getApplyQuickFilterFn: () => null,
      },
    ],
    [actionsCellRenderer],
  );

  return (
    <View data-testid={mainTestId}>
      <View.Header>
        <AdministrationHeader actions={[addButton]} crumbs={crumbs} title={title} />
      </View.Header>
      <View.Content>
        <AdminDataGrid
          disableColumnSelector
          disableRowSelectionOnClick
          ignoreDiacritics
          disableColumnMenu
          initialState={{
            sorting: { sortModel: [{ field: 'name', sort: 'asc' }] },
            pagination: { paginationModel: { pageSize: DEFAULT_ADMIN_DATA_GRID_PAGE_SIZE } },
          }}
          loading={fetching && !filters.length}
          pageSizeOptions={ADMIN_DATA_GRID_PAGE_SIZE_OPTIONS}
          onRowClick={goToResourceDetails}
          pagination
          columns={columns}
          rows={filters}
          slots={{
            toolbar: QuickSearchToolbarFilters,
            row: (props: GridRowProps) => <CustomGridRow {...props} dataTestId={mainTestId} />,
          }}
          slotProps={{
            loadingOverlay: {
              variant: 'linear-progress',
              noRowsVariant: 'skeleton',
            },
          }}
        />
        <Outlet />
      </View.Content>
    </View>
  );
};

const longButtonTitleSx = {
  display: {
    xs: 'none',
    sm: 'block',
  },
};

export default FiltersPage;
