import { KeyboardArrowDown } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Button, Divider, ListItem, ListItemText, Typography } from '@mui/material';
import { ChangeEvent, useState } from 'react';

import {
  channelsListAsSelectorItemsBySubtype,
  channelsListAsSelectorItemsBySubtypeAndOwnership,
  filtersListAsSelectorItems,
  useChannels,
  useFilters,
  usersListAsSelectorItemsBySubtype,
  useUsers,
} from '@serenityapp/client-data';
import {
  SelectorItemProps,
  SerenityForm,
  UserCreateEditFormConfigLegacy,
  UserCreateEditFormValuesLegacy,
} from '@serenityapp/components-react-common';
import {
  CheckboxField,
  ChipSelectorField,
  Drawer,
  Form,
  FormikField,
  SelectField,
  SwitchField,
  useMakeTestId,
  UserStatusChip,
} from '@serenityapp/components-react-web';
import { ServiceLevel } from '@serenityapp/core';
import {
  ConversationSubjectKind,
  UserKind,
  UserProps,
  UserStatus,
  UserTypeFn,
} from '@serenityapp/domain';
import { useIsFeatureEnabled, useRooms } from '@serenityapp/redux-store';

import { transformServiceLevelsToSelectorItemProps } from '../../../common/utils';
import ConfirmCloseDialog from '../../components/ConfirmCloseDialog';
import GroupedSelectField from '../../components/GroupedSelectField';
import { groupedRoomsTransformer } from '../../components/utils';
import UserActionsMenu from './UserActionsMenu';

type UserCreateEditFormProps = {
  handleFormClose: () => void;
  handleFormSubmit: (values: UserCreateEditFormValuesLegacy) => void;
  dataTestId?: string;
  initialValues?: Partial<UserCreateEditFormValuesLegacy>;
  title: string;
  isLoading?: boolean;
  isSaving: boolean;
  isEditMode?: boolean;
  user?: UserProps;
  emailError: boolean;
};

// This component is used for organizations where `removeUsername` feature is not enabled and
const UserCreateEditFormLegacy = ({
  handleFormClose,
  handleFormSubmit,
  dataTestId,
  initialValues,
  title,
  isLoading = false,
  isSaving = false,
  isEditMode = false,
  user,
  emailError,
}: UserCreateEditFormProps) => {
  const makeTestId = useMakeTestId('UserCreateEditForm', dataTestId);
  const mainTestId = makeTestId('');

  const isLocationFeatureEnabled = useIsFeatureEnabled('locations');

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);
  const [actionsMenuAnchor, setActionsMenuAnchor] = useState<null | HTMLElement>(null);
  const shouldDisplayActionsMenu = isEditMode && !!user;

  const { channels, fetching: isFetchingChannels } = useChannels();

  const allGeneralChannelsAsSelectorItems: SelectorItemProps[] =
    channelsListAsSelectorItemsBySubtype(channels, ConversationSubjectKind.GENERAL);

  const ownedResidentChannelsAsSelectorItems: SelectorItemProps[] =
    channelsListAsSelectorItemsBySubtypeAndOwnership(
      channels,
      ConversationSubjectKind.RESIDENT,
      user?.orgId,
      'owned',
    );

  const allResidentChannelsAsSelectorItems: SelectorItemProps[] =
    channelsListAsSelectorItemsBySubtype(channels, ConversationSubjectKind.RESIDENT);

  const ownedGeneralChannelsAsSelectorItems: SelectorItemProps[] =
    channelsListAsSelectorItemsBySubtypeAndOwnership(
      channels,
      ConversationSubjectKind.GENERAL,
      user?.orgId,
      'owned',
    );

  const { filters, fetching: isFetchingFilters } = useFilters();

  const filtersAsSelectorItems: SelectorItemProps[] = filtersListAsSelectorItems(filters);

  const { users, fetching: isFetchingUsers } = useUsers();

  const contactsAsSelectorItems = usersListAsSelectorItemsBySubtype(users, UserKind.CONTACT);
  const staffAsSelectorItems = usersListAsSelectorItemsBySubtype(users, UserKind.STAFF);

  const isCheckInFeatureEnabled = useIsFeatureEnabled('check-in');

  const { data, isLoading: isLoadingRooms } = useRooms();
  const rooms = groupedRoomsTransformer(data, isLoadingRooms);

  const serviceLevels: SelectorItemProps[] = transformServiceLevelsToSelectorItemProps(
    Object.values(ServiceLevel),
  );

  const userTypes: SelectorItemProps[] = [
    { id: 'staff', label: 'Staff' },
    { id: 'resident', label: 'Resident' },
    { id: 'contact', label: 'Contact' },
  ];

  const openActionsMenu = (event: React.MouseEvent<HTMLElement>) =>
    setActionsMenuAnchor(event.currentTarget);
  const closeActionsMenu = () => setActionsMenuAnchor(null);
  const onCancelConfirmDialogClick = () => setIsConfirmDialogOpen(false);

  const initialFormValues = {
    ...initialValues,
    temporaryPassword: 'Serenity!',
  };

  return (
    <>
      {shouldDisplayActionsMenu && (
        <UserActionsMenu
          anchorEl={actionsMenuAnchor}
          email={user?.email ?? ''}
          id={user?.id}
          name={user?.name || user?.fullName || ''}
          status={user?.status || UserStatus.NOT_AVAILABLE}
          subtype={user?.subtype || ''}
          onActionSuccess={handleFormClose}
          onClose={closeActionsMenu}
        />
      )}
      <ConfirmCloseDialog
        dataTestId={mainTestId}
        open={isConfirmDialogOpen}
        onCancelClick={onCancelConfirmDialogClick}
        onDiscardClick={handleFormClose}
      />
      <SerenityForm
        enableReinitialize
        validateOnBlur
        validateOnChange
        config={UserCreateEditFormConfigLegacy}
        initialValuesOverride={initialFormValues}
        onSubmit={handleFormSubmit}
      >
        {({ submitForm, resetForm, setFieldValue, values, dirty }) => {
          const isUserResident = UserTypeFn.isResident(values.userType);
          const isUserStaff = UserTypeFn.isStaff(values.userType);
          const userNameLabel = isUserResident ? 'User / Channel Name' : 'User Name';

          const onDrawerCloseClick = () => {
            if (dirty || isSaving) {
              setIsConfirmDialogOpen(true);
              return;
            }

            handleFormClose();
          };

          return (
            <Drawer open dataTestId={mainTestId} onClose={onDrawerCloseClick}>
              <Drawer.Header
                dataTestId={mainTestId}
                loading={
                  isLoading ||
                  isSaving ||
                  isFetchingChannels ||
                  isFetchingFilters ||
                  isFetchingUsers
                }
              >
                <Box sx={headerInfoSx}>
                  <Typography noWrap data-testid={makeTestId('title')} variant="h6">
                    {title}
                  </Typography>
                  {isEditMode && user?.status && (
                    <Box sx={secondaryHeaderLineSx}>
                      <UserStatusChip
                        dataTestId={makeTestId('status')}
                        size="small"
                        status={user.status}
                      />
                      <Typography sx={userTypeSx} variant="body2">
                        {values.userType}
                      </Typography>
                    </Box>
                  )}
                </Box>
                {shouldDisplayActionsMenu && (
                  <>
                    <Button
                      color="neutral"
                      data-testid={makeTestId('actions')}
                      disabled={isLoading}
                      endIcon={<KeyboardArrowDown sx={actionsButtonIconSx} />}
                      sx={actionsButtonSx}
                      onClick={openActionsMenu}
                    >
                      Actions
                    </Button>
                    <Divider orientation="vertical" sx={headerDividerSx} />
                  </>
                )}
              </Drawer.Header>
              <Drawer.Content>
                <Form disabled={isLoading || isSaving}>
                  <Typography display="block" variant="overline">
                    user role
                  </Typography>
                  <Typography gutterBottom display="block" variant="caption">
                    Defines user permissions and access
                  </Typography>
                  <Divider sx={dividerSx} />
                  <SelectField
                    dataTestId={makeTestId('userType')}
                    disabled={isEditMode}
                    items={userTypes}
                    name="userType"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      resetForm({});
                      setFieldValue('userType', event.target.value);
                    }}
                  />
                  {!!values.userType && (
                    <>
                      <Typography display="block" variant="overline">
                        general
                      </Typography>
                      <Divider sx={dividerSx} />
                      {!isUserResident && (
                        <FormikField
                          dataTestId={makeTestId('fullName')}
                          name="fullName"
                          type="text"
                        />
                      )}
                      <FormikField
                        dataTestId={makeTestId('userName')}
                        label={userNameLabel}
                        name="userName"
                        type="text"
                      />
                      {!isUserResident && (
                        <>
                          <FormikField
                            dataTestId={makeTestId('email')}
                            name="email"
                            serverError={
                              emailError ? 'This email address is already in use' : undefined
                            }
                            type="email"
                          />
                          <FormikField
                            dataTestId={makeTestId('phoneNumber')}
                            name="phoneNumber"
                            type="phone"
                          />
                        </>
                      )}
                      {!isUserResident && (
                        <>
                          <Typography display="block" variant="overline">
                            organization info
                          </Typography>
                          <Divider sx={dividerSx} />
                        </>
                      )}
                      {isUserStaff && (
                        <>
                          <FormikField
                            dataTestId={makeTestId('jobTitle')}
                            name="jobTitle"
                            type="text"
                          />
                          <FormikField
                            dataTestId={makeTestId('department')}
                            name="department"
                            type="text"
                          />
                          <FormikField
                            dataTestId={makeTestId('workerCategory')}
                            name="workerCategory"
                            type="text"
                          />
                        </>
                      )}
                      {!isUserResident && (
                        <>
                          <ChipSelectorField
                            dataTestId={makeTestId('residentChannels')}
                            disabled={isLoading}
                            items={
                              isUserStaff
                                ? allResidentChannelsAsSelectorItems
                                : ownedResidentChannelsAsSelectorItems
                            }
                            name="residentChannels"
                          />
                          <ChipSelectorField
                            dataTestId={makeTestId('generalChannels')}
                            disabled={isLoading}
                            items={
                              isUserStaff
                                ? allGeneralChannelsAsSelectorItems
                                : ownedGeneralChannelsAsSelectorItems
                            }
                            name="generalChannels"
                          />
                        </>
                      )}
                      {isUserResident && !isEditMode && (
                        <>
                          <Typography display="block" variant="overline">
                            staff & contact members
                          </Typography>
                          <Divider sx={dividerSx} />
                          <ChipSelectorField
                            dataTestId={makeTestId('familyAndFriends')}
                            disabled={isLoading}
                            items={contactsAsSelectorItems}
                            name="familyAndFriends"
                          />
                          <ChipSelectorField
                            dataTestId={makeTestId('staff')}
                            disabled={isLoading}
                            items={staffAsSelectorItems}
                            name="staff"
                          />
                          <Typography display="block" variant="overline">
                            location
                          </Typography>
                          <Divider sx={dividerSx} />
                          <ChipSelectorField
                            dataTestId={makeTestId('filters')}
                            disabled={isLoading}
                            items={filtersAsSelectorItems}
                            name="locations"
                          />
                        </>
                      )}
                      <FormikField dataTestId={makeTestId('description')} name="description" />
                      {!isUserResident && !isEditMode && (
                        <>
                          <Typography display="block" variant="overline">
                            Settings
                          </Typography>
                          <Divider sx={dividerSx} />
                          <CheckboxField
                            dataTestId={makeTestId('sendInvitationEmail')}
                            name="sendInvitationEmail"
                          />
                          <Alert severity="info" sx={alertSx}>
                            <strong>Note: </strong>
                            {UserCreateEditFormConfigLegacy.strings.temporaryPasswordInfo}
                          </Alert>
                          <FormikField
                            dataTestId={makeTestId('temporaryPassword')}
                            helperText="Expires in 90 days"
                            name="temporaryPassword"
                          />
                        </>
                      )}
                      {isUserResident && (
                        <>
                          <Typography display="block" variant="overline">
                            location and service
                          </Typography>
                          <Divider sx={dividerSx} />

                          <SelectField
                            dataTestId={makeTestId('serviceLevel')}
                            items={serviceLevels}
                            name="serviceLevel"
                          />
                          {!isLocationFeatureEnabled && (
                            <GroupedSelectField
                              dataTestId={makeTestId('rooms')}
                              groups={rooms}
                              label="Room"
                              name="room"
                            />
                          )}
                        </>
                      )}

                      {isUserResident && isCheckInFeatureEnabled && (
                        <>
                          <Typography display="block" variant="overline">
                            workflows
                          </Typography>
                          <Divider sx={dividerSx} />
                          <ListItem key={'check-in'} sx={switchFieldSx}>
                            <ListItemText
                              primary="Check-In"
                              secondary="Enable to allow participation in the Check-In workflow."
                            />
                            <SwitchField name="checkIn" />
                          </ListItem>
                        </>
                      )}
                    </>
                  )}
                </Form>
              </Drawer.Content>
              <Drawer.Footer>
                <Button
                  data-testid={makeTestId('close')}
                  disabled={isSaving}
                  onClick={onDrawerCloseClick}
                >
                  Close
                </Button>
                <LoadingButton
                  data-testid={makeTestId('save')}
                  disabled={isLoading || !dirty}
                  loading={isSaving}
                  variant="contained"
                  onClick={submitForm}
                >
                  {!isUserResident && values.sendInvitationEmail && !isEditMode
                    ? 'Save and invite'
                    : 'Save'}
                </LoadingButton>
              </Drawer.Footer>
            </Drawer>
          );
        }}
      </SerenityForm>
    </>
  );
};

const actionsButtonSx = {
  color: 'text.primary',
  textTransform: 'capitalize',
  mr: 1,
};

const actionsButtonIconSx = {
  color: 'action.active',
};

const headerInfoSx = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '11rem',
  flexGrow: 1,
};

const secondaryHeaderLineSx = {
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
  gap: 1,
};

const userTypeSx = {
  textTransform: 'capitalize',
  color: 'text.secondary',
};

const alertSx = {
  mb: 2,
};

const dividerSx = {
  mb: 2.25,
};

const headerDividerSx = {
  height: '40px',
};

const switchFieldSx = {
  px: 0,
};

export default UserCreateEditFormLegacy;
