import * as Sentry from '@sentry/react';
import { Cache, ResolveInfo } from '@urql/exchange-graphcache';
import { ClientApi } from '@serenityapp/api-client-graph';

export default function userCreateNext(
  result: ClientApi.User.Api.CreateNext.MutationResult,
  _args: ClientApi.User.Api.CreateNext.Variables,
  cache: Cache,
  info: ResolveInfo,
) {
  const createdUserOutput = result?.result;

  if (info.error || !createdUserOutput || !createdUserOutput.success) {
    return;
  }

  const {
    id,
    name,
    orgId,
    email,
    status,
    subtype,
    subsubtype,
    initials,
    avatar,
    archived,
    fullName,
  } = createdUserOutput.user;

  const user: ClientApi.User.Fragments.ListType = {
    id,
    fullName,
    name,
    orgId,
    email,
    status,
    subtype,
    subsubtype,
    initials,
    avatar,
    archived,
    __typename: 'User',
  };

  const node = { node: user, __typename: 'ViewerUserEdge' } as {
    node: ClientApi.User.Fragments.ListType;
    __typename: 'ViewerUserEdge';
  };

  const q = {
    query: ClientApi.Viewer.Api.Users.Query,
    variables: {},
    requestPolicy: 'cache-and-network',
  };

  cache.updateQuery<ClientApi.Viewer.Api.Users.Response>(q, (data) => {
    if (!data) {
      Sentry.addBreadcrumb({
        message: 'Failed to get current viewer users query data while trying to add a user',
        data: { q },
      });
      return null;
    }
    const updatedEdges = [node, ...data.viewer.users.edges];

    const newUsers = {
      ...data.viewer.users,
      edges: updatedEdges,
    };

    data.viewer.users = newUsers;

    return data;
  });
}
